<script lang="tsx">
import {VSelect} from "vuetify/lib/components/VSelect/VSelect.mjs";
import {BaseDropdownProps} from "~/components/LComponents/Props/BaseDropdownProps";
import {BaseInputProps} from "~/components/LComponents/Props/BaseInputProps.client";
import {TextInputProps} from "~/components/LComponents/Props/TextInputProps";
import {StateProps} from "~/components/LComponents/Props/StateProps";
import LField from "~/components/LComponents/LField.vue";
import {ThemeProps} from '~/components/LComponents/Props/ThemeProps';

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number, Array, null,],
      required: true,
    },
    ...BaseDropdownProps,
    ...BaseInputProps,
    ...TextInputProps,
    ...ThemeProps,
    ...StateProps,
  },
  setup: function(props, context) {
    const model = computed<string | number | unknown[]>({
        get: () => props.modelValue,
        set: (value) => {
          context.emit('update:modelValue', value),
          setFocus(false);
        },
    });
    const isFocused = ref<boolean>(false);
    const menuZIndex = 9001; // bigger then v-dialog
    const isMenuVisible = ref<boolean>(false);
    const setFocus = (newIsFocus: boolean) => {
      isFocused.value = newIsFocus;
    };

    watch(isMenuVisible, () => {
      onMenuUpdate();
    });

    const onMenuUpdate = () => {
      //TODO: jak najszybciej to usunąć
      if (!isMenuVisible.value) {
        const element = document.querySelector('.v-field--focused');
        if (element) {
          nextTick(() => {
            element.classList.remove('v-field--focused');
            element.classList.remove('v-field--active');
          });
        }
        const outline = document.querySelector('.v-field__outline.text-primary');
        if (outline) {
          nextTick(() => {
            outline.classList.remove('text-primary');
          });
        }
        setFocus(false);
      }
    };

    return () => (
        <LField
          isFocused={isFocused.value}
          class={props.class}
          required={props.required}
          label={props.label}
          id={props.id}>
          <VSelect
              id={`select${props.id}`}
              onBlur={() => setFocus(false)}
              onFocus={() => setFocus(true)}
              v-model={model.value}
              v-model:menu={isMenuVisible.value}
              class={['l-select',]}
              style={props.style}
              chips={props.chips}
              closable-chips={props.closableChips}
              eager={props.eager}
              hide-no-data={props.hideNoData}
              hide-selected={props.hideSelected}
              menu-icon={props.menuIcon}
              multiple={props.multiple}
              no-data-text={props.noDataText}
              open-on-clear={props.openOnClear}
              transition={props.transition}
              suffix={props.suffix}
              items={props.items}
              color={props.color}
              clearable={props.clearable}
              return-object={props.returnObject}
              item-title={props.itemTitle}
              item-value={props.itemValue}
              hide-details={"auto"}
              disabled={props.disabled}
              rules={props.required ? [v => !!v || 'Pole jest wymagane',] : []}
              label={!model.value ? props.placeholder : undefined}
              menu-props={{zIndex: menuZIndex,}}/>
        </LField>
    );
  },
});

</script>
<style scoped lang="scss">
:deep(.v-field.v-field--focused) .v-field-label {
  opacity: 0;
}
</style>
