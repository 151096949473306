import {ComponentObjectPropsOptions, PropType} from "@vue";
import {mdiChevronDown} from "@mdi/js";
export type SelectItem = string | (string | number)  []
type BaseDropdownPropsType = {
    chips: boolean,
    closableChips: boolean,
    eager: boolean,
    hideNoData: boolean,
    hideSelected: boolean,
    menuIcon: string,
    multiple: boolean,
    noDataText: string,
    openOnClear: boolean,
    transition: boolean | string | object,
    // todo sprawdzić czy już działa
    items: any[],
    returnObject: boolean,
    itemTitle: string,
    itemValue: string
    placeholder: string
};
export const BaseDropdownProps: ComponentObjectPropsOptions<BaseDropdownPropsType> = {
  chips: {
      type: Boolean,
      default: false,
  },
  closableChips: {
      type: Boolean,
      default: false,
  },
  eager: {
      type: Boolean,
      default: false,
  },
  hideNoData: {
      type: Boolean,
      default: true,
  },
  hideSelected: {
      type: Boolean,
      default: false,
  },
  menuIcon: {
      type: String,
      default: mdiChevronDown,
  },
  multiple: {
      type: Boolean,
      default: false,
  },
  noDataText: {
      type: String,
      default: 'Brak danych',
  },
  openOnClear: {
      type: Boolean,
      default: false,
  },
  transition: {
      type: [Boolean, String, Object,],
      default: 'scale-transition',
  },
  items: {
      type: Array as PropType<any[]>,
      required: true,
  },
  returnObject: {
      type: Boolean,
      default: false,
  },
  itemTitle: {
    type: String,
    default: 'name',
  },
  itemValue: {
      type: String,
      default: 'value',
  }, 
  placeholder: {
      type: String,
      default: '',
  },
};
